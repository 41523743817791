import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class HomeTestimonials {
  constructor(container) {
    this.DOM = { container }
    this.DOM.cards = this.DOM.container.querySelectorAll('.TestimonialCard')

    if (!this.DOM.cards.length) return

    this.setAnimation()
  }

  setAnimation() {
    const { container, cards } = this.DOM

    this.tl = gsap
      .timeline({
        defaults: { ease: 'power3.out', duration: 1 },
        scrollTrigger: {
          trigger: container,
          start: 'top 50%',
          end: 'bottom top'
        }
      })
      .fromTo(cards, { opacity: 0, xPercent: 20 }, { opacity: 1, xPercent: 0, stagger: 0.085 }, 0)
  }

  destroy() {
    const { tl } = this

    if (tl) tl.kill()
  }
}
