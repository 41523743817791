import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from './page-intro'

export const HomeIntro = () => {
  const { intro, body } = domStorage
  const { hasSmoothScroll, viewScroll } = viewStorage

  const pageHero = viewStorage.current.querySelector('.PageHero.--home')
  const pageHeroTitle = pageHero.querySelector('h1')
  const pageHeroBtn = pageHero.querySelector('.Btn')
  const pageHeroWordsC = pageHero.querySelector('.words')
  const pageHeroWords = pageHero.querySelectorAll('.words span')
  const pageHeroVisual = pageHero.querySelector('.visual__container')

  gsap
    .timeline({
      delay: 0.2,
      defaults: { duration: 1, ease: 'expo.inOut' },
      onStart: () => {
        body.classList.remove('--preloading')
        body.classList.remove('--loading')
        body.classList.add('--show-words')
      },
      onComplete: () => {
        ScrollTrigger.refresh()
        body.classList.remove('--animating')
        body.classList.remove('--intro')
        body.classList.remove('--show-words')

        globalStorage.firstLoad = false

        // Removing pageHeroWordsC from pageHero
        pageHero.removeChild(pageHeroWordsC)

        // Showing the highlight
        pageHeroTitle.classList.add('--show-highlight')

        // Cleaning styles
        gsap.set(pageHeroVisual, { clearProps: 'clipPath' })
        gsap.set(pageHeroTitle, { clearProps: 'all' })
        gsap.set(pageHeroBtn, { clearProps: 'all' })
      }
    })
    .fromTo(pageHeroVisual, { clipPath: 'inset(100% 0% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)', duration: 1.2 } , 0)
    .fromTo(pageHeroVisual.children[0], { scale: 1.2 }, { scale: 1, ease: 'power3' }, 0.5)
    .fromTo(pageHeroWords[0], { xPercent: -150 }, { xPercent: 0, duration: 1.5 }, 0.85)
    .fromTo(pageHeroWords[1], { xPercent: 150 }, { xPercent: 0, duration: 1.5 }, 0.9)
    .to(pageHeroWords[0], { xPercent: 150, duration: 1.5 }, 2.25)
    .to(pageHeroWords[1], { xPercent: -150, duration: 1.5 }, 2.35)
    .add(pageIntro(), 3.25)

    // .fromTo(pageHeroTitle, { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'power3.inOut' }, 3.25)
    // .fromTo(pageHeroBtn, { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'power3.inOut' }, 3.35)
    // .fromTo(domStorage.header, { yPercent: -100 }, { yPercent: 0, ease: 'power3.inOut' }, 3.5)
}