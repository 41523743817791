/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import PageComponents from './pages/components'
import PageHome from './pages/page-home'
import PageJoinUs from './pages/page-join-us'
import PageAccompaniment from './pages/page-accompaniment'
import PageAbout from './pages/page-about'
import PageQuotationRequest from './pages/page-quotation-request'
import PagePatronage from './pages/page-patronage'
import PagePrestation from './pages/page-prestation'
import SingleJobOffer from './pages/single-job-offer'
import SingleOffer from './pages/single-offer'
import SingleRealisation from './pages/single-realisation'
import ArchiveRealisations from './pages/archive-realisations'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  renderers: {
    default: Page,
    pageHome: PageHome,
    pageJoinUs: PageJoinUs,
    pageComponents: PageComponents,
    pageAccompaniment: PageAccompaniment,
    pageAbout: PageAbout,
    pageQuotationRequest: PageQuotationRequest,
    pagePatronage: PagePatronage,
    pagePrestation: PagePrestation,
    singleJobOffer: SingleJobOffer,
    singleOffer: SingleOffer,
    singleRealisation: SingleRealisation,
    archiveRealisations: ArchiveRealisations
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
