export default class GetFullHeight {
  constructor() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight.bind(this))
  }


  setHeight() {
    const wh = window.innerHeight
    document.body.style.setProperty('--screen-height', `${wh}px`) 
  }
}
