import { isDEVMODE, domStorage, globalStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from '../animations/page-intro'

// We'll create a <div></div> for page transition
let pageTransitionDiv

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    const { header, footer } = domStorage
    
    // GSAP Timeline
    gsap.timeline({
      defaults: {  ease: 'power3.inOut', duration: 1 },
      onStart: () => {
        // Updating the ScrollTriggers
        ScrollTrigger.refresh()

        // Closing the Header
        globalStorage.Header.close()
      },
      onComplete: () => {
        // Display the new page
        done()
        // Removing body className while animating
        domStorage.body.classList.remove('--animating')
        // Removing the pageTransitionDiv from the DOM
        domStorage.body.removeChild(pageTransitionDiv)

        gsap.set(header, { clearProps: 'all' })
      },
    })
    .to(footer, { x: 0, overwrite: true }, 0)
    .to(pageTransitionDiv, { scaleX: 0, transformOrigin: '0% 0%', overwrite: true }, 0)
    .add(pageIntro(0), 0.75)

  }

  onLeave({ from, trigger, done }) {
    const { header, footer } = domStorage

    // We need to create a div for page transitions
    // <div class="PageTransition"></div>
    pageTransitionDiv = document.createElement('div')
    pageTransitionDiv.className = 'PageTransition'

    // GSAP Timeline
    gsap
    .timeline({
      delay: 0.2,
      defaults: { ease: 'power3.inOut', duration: 1 },
      onStart: () => {
        // We append the pageTransitionDiv to the body
        document.body.appendChild(pageTransitionDiv)
      },
      onComplete: () => { 
        // Remove the last page
        done()
      }
    })
    .to(header, { opacity: 0 }, 0)
    .fromTo(pageTransitionDiv, { scaleX: 0 }, { scaleX: 1, transformOrigin: '100% 100%' }, 0)
    .to([from, footer], { x: -50 }, 0.05)
  }
}
