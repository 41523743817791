import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import AccompanimentSteps from '../modules/accompaniment-steps'

export default class PageAccompaniment extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      stepsSection: viewStorage.current.querySelector('.AccompanimentSteps')
    }


    this.init()

  }

  init() {
    const { stepsSection } = this.DOM 

    if (stepsSection) this.stepsModule = new AccompanimentSteps(stepsSection)

  }

  onLeaveCompleted() {
    const { stepsModule } = this
    
    if (stepsModule) stepsModule.destroy()
  }
}
