import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export const pageIntro = (delay = 0.5) => {
  const { body } = domStorage
  const { current } = viewStorage
  const { taxiView } = current.dataset

  // All sections of the current page
  const allSections = [...current.children]
  
  // Page Headers
  const pageHeader = current.querySelector('.PageH')
  const pageHeaderTitle = pageHeader.querySelector('h1')
  const pageHeaderIntro = pageHeader.querySelector('.wswyg--content')
  const pageHeaderBtn  = pageHeader.querySelector('.Btn')
  const pageHeaderBanner = pageHeader.querySelector('.DynamicBanner')
  const pageHeaderBackLink = pageHeader.querySelector('.BackLink')
  // ——— Job Offer
  const pageHeaderJobInfos = pageHeader.querySelector('.JobInfos')
  const pageHeaderSharing = pageHeader.querySelector('.Sharing')
  const pageHeaderJobContent = current.querySelector('.job-content')
  // ——— Quotation Request
  const pageHeaderContactInfos = pageHeader.querySelector('.ContactInfos')
  const pageHeaderSocials = pageHeader.querySelector('.Socials')
  const pageHeaderQuotationForm = pageHeader.querySelector('.QuotationForm')
  // ——— Patronage 
  const pageHeaderPatronageForm = pageHeader.querySelector('.PatronageForm')
  // ——— Single Realisation
  const pageHeaderCategories = pageHeader.querySelector('.Categories')
  const pageHeaderSwiper = pageHeader.querySelector('.swiper')
  // ——— Single Offer
  const pageHeaderLogo = pageHeader.querySelector('.logo')
  
  // Page Content (= all sections after page header)
  const pageContent = allSections.slice(1)

  const tl = gsap
    .timeline({
      delay,
      defaults: { duration: 1, ease: 'power3.inOut' },
      onStart: () => {
        // Be sure Chrome reinit the scroll
        setTimeout(() => window.scrollTo(0, 0), 100)
      },
      onComplete: () => {
        // Showing the highlight
        if (pageHeaderTitle) pageHeaderTitle.classList.add('--show-highlight')

         // Cleaning styles
        if (pageHeaderTitle) gsap.set(pageHeaderTitle, { clearProps: 'all' })
        if (pageHeaderBackLink) gsap.set(pageHeaderBackLink, { clearProps: 'all' })
        if (pageHeaderIntro) gsap.set(pageHeaderIntro, { clearProps: 'all' })
        if (pageHeaderBtn) gsap.set(pageHeaderBtn, { clearProps: 'all' })
        if (pageHeaderBanner) gsap.set(pageHeaderBanner, { clearProps: 'all' })
        if (pageContent.length) gsap.set(pageContent, { clearProps: 'all' })
        if (domStorage.header) gsap.set(domStorage.header, { clearProps: 'opacity' })

        if (pageHeaderSharing) gsap.set(pageHeaderSharing, { clearProps: 'all' })
        if (pageHeaderSharing) gsap.set(pageHeaderSharing, { clearProps: 'all' })
        if (pageHeaderJobContent) gsap.set(pageHeaderJobContent, { clearProps: 'all' })

        if (pageHeaderSwiper) gsap.set(pageHeaderSwiper, { clearProps: 'all' })
        if (pageHeaderCategories) gsap.set(pageHeaderCategories, { clearProps: 'all' })

        if (pageHeaderContactInfos) gsap.set(pageHeaderContactInfos, { clearProps: 'all' })
        if (pageHeaderSocials) gsap.set(pageHeaderSocials, { clearProps: 'all' })
        if (pageHeaderQuotationForm) gsap.set(pageHeaderQuotationForm, { clearProps: 'all' })
      }
    })
    
    if (pageHeaderTitle) tl.fromTo(pageHeaderTitle, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0)
    if (pageHeaderLogo) tl.fromTo(pageHeaderLogo, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0)
    if (pageHeaderBackLink) tl.fromTo(pageHeaderBackLink, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0)
    if (pageHeaderIntro) tl.fromTo(pageHeaderIntro, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.1)
    if (pageHeaderBtn) tl.fromTo(pageHeaderBtn, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.15)
    if (pageHeaderBanner) tl.fromTo(pageHeaderBanner, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.2)
    if (pageContent.length) tl.fromTo(pageContent, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.5)
    if (domStorage.header) tl.to(domStorage.header, { opacity: 1 }, 0.7)

    if (pageHeaderSharing) tl.fromTo(pageHeaderSharing, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0)
    if (pageHeaderJobInfos) tl.fromTo(pageHeaderJobInfos, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.1)
    if (pageHeaderJobContent) tl.fromTo(pageHeaderJobContent, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.2)

    if (pageHeaderContactInfos) tl.fromTo(pageHeaderContactInfos, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.15)
    if (pageHeaderSocials) tl.fromTo(pageHeaderSocials, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.2)
    if (pageHeaderQuotationForm) tl.fromTo(pageHeaderQuotationForm, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.2)

    if (pageHeaderCategories) tl.fromTo(pageHeaderCategories, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0)
    if (pageHeaderSwiper) tl.fromTo(pageHeaderSwiper, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.2)

    if (pageHeaderPatronageForm) tl.fromTo(pageHeaderPatronageForm, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.2)

  return tl
}
