import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import WknSwiper from './wkn-swiper'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)

export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    this.DOM.brand = this.DOM.el.querySelector('.NavBrand')
    this.DOM.toggle = this.DOM.el.querySelector('.NavToggle')
    this.DOM.menuContainer = this.DOM.el.querySelector('.NavContainer')

    this.DOM.navLinksSubmenu = this.DOM.el.querySelectorAll('.NavLinkSubmenu')
    this.DOM.submenus = this.DOM.el.querySelectorAll('.NavSubmenu')
    this.DOM.submenusSwipers = this.DOM.el.querySelectorAll('.NavSubmenu .swiper')
    this.DOM.submenusBackButtons = this.DOM.el.querySelectorAll('.NavSubmenu .BackLink')

    this.submenuSwiper = null
    this.currentSubmenu = null

    this.isOpen = false

    this.setEvents()
  }

  setEvents() { 
    const { overlay } = domStorage
    const { el, toggle, navLinksSubmenu, submenusBackButtons } = this.DOM

    // Hide the header by default
    gsap.set(el, { opacity: 0 })

    // Header scroll up/down
    this.setScrollHeader()

    // Toggle
    this.toggleMenu = this.toggleMenu.bind(this)
    toggle.addEventListener('click', this.toggleMenu)

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    // Submenus
    this.openSubmenu = this.openSubmenu.bind(this)
    this.closeSubmenu = this.closeSubmenu.bind(this)
    this.clickOutsideSubmenu = this.clickOutsideSubmenu.bind(this)

    if (navLinksSubmenu.length) navLinksSubmenu.forEach(link => link.addEventListener('click', this.openSubmenu))
    if (submenusBackButtons.length) submenusBackButtons.forEach(button => button.addEventListener('click', this.closeSubmenu))

    // overlay
    overlay.addEventListener('click', (e) => globalStorage.menuOpen && this.close())

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)
  }

  setScrollHeader() {
    const { body } = domStorage
    const { el } = this.DOM

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 0,
      onUp: () => {
        !el.classList.contains('--visible') && el.classList.add('--visible')
      },
      onDown: () => {
        el.classList.contains('--visible') && el.classList.remove('--visible')
        body.classList.remove('--show-submenu')
        if (this.currentSubmenu) this.currentSubmenu.classList.remove('--show-submenu')
      }
    })

    this.scrolledObserver = Observer.create({
      target: window,
      type: 'scroll',
      onChange: (self) => {
        if (window.scrollY > 50 && !body.classList.contains('--scrolled')) body.classList.add('--scrolled')
        if (window.scrollY <= 50 && body.classList.contains('--scrolled')) body.classList.remove('--scrolled')
      }
    })
  }

  openSubmenu(e) {
    e && e.preventDefault()

    const { currentTarget } = e
    const navItem = currentTarget.parentNode
    const navSubmenuSwiper = navItem.querySelector('.NavSubmenu .swiper')

    if (this.currentSubmenu) this.currentSubmenu.classList.remove('--show-submenu')
    if (this.submenuSwiper) this.submenuSwiper.destroy()

    // Updating the currentSubemnu variable
    this.currentSubmenu = navItem

    // Adding the className for the current navItem that contains the submenu
    this.currentSubmenu.classList.add('--show-submenu')    
    domStorage.body.classList.add('--show-submenu')    

    // Adding the swiper for cards
    if (navSubmenuSwiper) this.submenuSwiper = new WknSwiper(navSubmenuSwiper, { slidesPerView: 'auto' })
    
     // Adding the event on document
     setTimeout(() => document.addEventListener('click', this.clickOutsideSubmenu), 50)
  }

  closeSubmenu(e) {
    e && e.preventDefault()

    // Removing the className for each submenu item
    this.currentSubmenu.classList.remove('--show-submenu')
    domStorage.body.classList.remove('--show-submenu')
    // Removing the event on document
    document.removeEventListener('click', this.clickOutsideSubmenu)
    // Updating the currentSubmenu variable
    this.currentSubmenu = null
  }

  clickOutsideSubmenu(e) {
    e && e.preventDefault()

    if (!this.currentSubmenu.contains(e.target)) this.closeSubmenu()
  }

  toggleMenu(e) {
    e.preventDefault()

    globalStorage.menuOpen ? this.close() : this.open()
  }

  open() {
    // Updating globalStorage variable
    globalStorage.menuOpen = true

    // Adding body className
    domStorage.body.classList.add('--show-menu')

    // Stop smooth scroll
    if (viewStorage.viewScroll) viewStorage.viewScroll.scroll.stop()
  }

  close() {
    // Updating globalStorage variable
    globalStorage.menuOpen = false

    // Removing body classNames
    domStorage.body.classList.remove('--show-menu')
    domStorage.body.classList.remove('--show-submenu')

    // Removing currentSubmenu className if it exists
    if (this.currentSubmenu) this.currentSubmenu.classList.remove('--show-submenu')

    // Init smooth scroll
    if (viewStorage.viewScroll) viewStorage.viewScroll.scroll.start()
  }

  onResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
