import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class SingleOffer extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      headerBtn: viewStorage.current.querySelector('.PageHeaderOffer .Btn'),
      partnersSwiper: viewStorage.current.querySelector('.Partners .swiper'),
      offerSpeaker: viewStorage.current.querySelector('.OfferSpeaker')
    }

    this.init()
  }

  init() {
    const { headerBtn, offerSpeaker, partnersSwiper } = this.DOM

    if (partnersSwiper) this.partnersSwiperModule = new WknSwiper(partnersSwiper, { slidesPerView: 'auto' })
    if (headerBtn && offerSpeaker) this.setAnchor()
  }

  setAnchor() {
    const { headerBtn, offerSpeaker } = this.DOM

    if (!offerSpeaker) return gsap.set(headerBtn, { display: 'none' })

    headerBtn.addEventListener('click', this.goToOfferSpeaker.bind(this))
  }

  goToOfferSpeaker(e) {
    e.preventDefault()

    const { offerSpeaker } = this.DOM

    gsap.to(window, { scrollTo: offerSpeaker, duration: 1, ease: 'circ.inOut' })
  }

  onLeaveCompleted() {
    const {  partnersSwiperModule, headerBtn } = this

    if (partnersSwiperModule) partnersSwiperModule.destroy()
    if (headerBtn) headerBtn.removeEventListener('click', this.goToOfferSpeaker.bind(this))
  }
}
