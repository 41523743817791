export default class Sharing {
  constructor(container) {
    this.DOM = { container }
    this.DOM.clipBoardButton = this.DOM.container.querySelector('.copy-to-clipboard')

    if (!this.DOM.clipBoardButton) return

    this.DOM.clipBoardButton.addEventListener('click', this.copyToClipBoard.bind(this))
  }

  copyToClipBoard(e) {
    e.preventDefault()

    const { currentTarget } = e

    navigator.clipboard.writeText(window.location.href)
    currentTarget.classList.add('--copied')

    setTimeout(() => currentTarget.classList.remove('--copied'), 1000)
  }

  destroy() {
    const { clipBoardButton } = this.DOM

    if (clipBoardButton) clipBoardButton.removeEventListener('click', this.copyToClipBoard.bind(this))
  }
}
