import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'

export default class ArchiveRealisations extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      navigationSwiper: viewStorage.current.querySelector('.AllRealisationsNav .swiper')
    }

    this.init()
  }

  init() {
    const { navigationSwiper } = this.DOM

    if (navigationSwiper) this.navigationSwiperModule = new WknSwiper(navigationSwiper, { slidesPerView: 'auto' })
  }

  onLeaveCompleted() {
    const { navigationSwiperModule } = this

    if (navigationSwiperModule) navigationSwiperModule.destroy()
  }
}
