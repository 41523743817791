import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import MobileSwiper from '../modules/mobile-swiper'
import WknSwiper from '../modules/wkn-swiper'
import Numbers from '../modules/numbers'
import HomeTestimonials from '../animations/home-testimonials'

export default class PageHome extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      realisationsSwiper: viewStorage.current.querySelector('.RelatedRealisations .swiper'),
      numbersSection: viewStorage.current.querySelector('.Numbers'),
      testimonialSection: viewStorage.current.querySelector('.Testimonials'),
      testimonialsSwiper: viewStorage.current.querySelector('.Testimonials .swiper')
    }


    this.init()
  }

  init() {
    const { realisationsSwiper, numbersSection, testimonialSection, testimonialsSwiper } = this.DOM 

    if (realisationsSwiper) this.realisationsSwiperModule = new MobileSwiper(realisationsSwiper)
    if (numbersSection) this.numbersModule = new Numbers(numbersSection)
    if (testimonialSection) this.testimonialAnimation = new HomeTestimonials(testimonialSection)
    if (testimonialsSwiper) this.testimonialsSwiper = new WknSwiper(testimonialsSwiper, { slidesPerView: 'auto' })
  }

  onLeaveCompleted() {
    const { realisationsSwiperModule, numbersModule, testimonialsSwiper, testimonialAnimation } = this
    
    if (realisationsSwiperModule) realisationsSwiperModule.destroy()
    if (numbersModule) numbersModule.destroy()
    if (testimonialsSwiper) testimonialsSwiper.destroy()
    if (testimonialAnimation) testimonialAnimation.destroy()
  }
}
