import { isDEVMODE, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Numbers {
  constructor(container) {
    this.DOM = { container }
    this.DOM.numbers = this.DOM.container.querySelectorAll('.Number')
    
    if (!this.DOM.numbers.length) return

    this.DOM.wrapper = this.DOM.container.querySelector('.wrapper')
    this.DOM.title = this.DOM.container.querySelector('h2')

    this.settings = {
      ease: 'circ.out',
      duration: 1.2
    }

    this.setParallaxTitle()
    this.setIncrementNumbers()
  }

  setParallaxTitle() {
    const { container, title } = this.DOM

    if (!title) return

    this.parallax = gsap
      .timeline({
        defaults: { ease: 'none' },
        scrollTrigger: {
          trigger: container,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 0.5
        }
      })
      .fromTo(title, { xPercent: 50 }, { xPercent: -130 }, 0)
  }

  setIncrementNumbers() {
    const { wrapper, numbers } = this.DOM
    const { duration, ease } = this.settings

    const numberWithSpaces = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

    this.numbersArr = []

    numbers.forEach(number => {
      const numberText = number.querySelector('.inner span')
      const numberData = number.dataset.number

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper,
          start: 'top 80%',
          end: 'bottom top',
          scrub: false
        }
      })

      // Animation conditions according the type of number
      if (numberData.includes('.') || numberData.includes(',')) {
        tl.to(numberText, { textContent: numberData, duration, ease, snap: { textContent: 0.1 } }, 0)
      } else {
        tl.to(numberText, { textContent: numberData, duration, ease, snap: { textContent: 1 }, onUpdate: () => numberText.innerHTML = numberWithSpaces(Math.ceil(numberText.textContent)) }, 0)
      }

      this.numbersArr.push(tl)
    })
  }

  destroy() {
    const { parallax, numbersArr } = this

    if (parallax) parallax.kill()
    if (numbersArr.length) numbersArr.forEach(number => number.kill())
  }
} 
