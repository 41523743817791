/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage } from './_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { GlobalIntro } from './animations/global-intro'
import { HomeIntro } from './animations/home-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewModals from './modules/view-modal'

import DynamicBanners from './modules/dynamic-banners'

let dynamicBanners = null

const toggleHeaderTheme = () => {
  if (!domStorage.header) return

  if (viewStorage.current.dataset.header === 'light') {
    domStorage.header.classList.add('--light')
  } else {
    domStorage.header.classList.remove('--light')
  }
}

/* --- DOMContentLoaded Function --- */
export const onReady = () => {
  const { body } = domStorage

  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(body)
  viewStorage.viewInView = new viewInView(body)
  viewStorage.viewModals = new viewModals(body)

  ScrollTrigger.refresh()

  // Intro
  // if (viewStorage.current.dataset.)
  viewStorage.current.dataset.taxiView === 'pageHome' ? HomeIntro() : GlobalIntro()

  // Header theme
  toggleHeaderTheme()

  // DynamicBanner
  if (viewStorage.current.querySelectorAll('.DynamicBanner')) dynamicBanners = new DynamicBanners()
}

export const onEnter = (to, trigger) => {
  // LoadingClasses
  const { body } = domStorage
  body.classList.remove('--loading')

  // ScrollTop
  const { hasSmoothScroll } = viewStorage
  window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  setTimeout(() => {
    body.style.overflow = ''
    if (hasSmoothScroll) viewStorage.viewScroll = new viewScroll()
    viewStorage.viewPrllx = new viewPrllx()
    viewStorage.viewInView = new viewInView()
    viewStorage.viewModals = new viewModals()
  }, 150)

  // Header theme
  toggleHeaderTheme()

  // DynamicBanner
  if (viewStorage.current.querySelectorAll('.DynamicBanner')) dynamicBanners = new DynamicBanners()
}

export const onEnterCompleted = (to, from, trigger) => {
  const { hasSmoothScroll, viewScroll } = viewStorage
  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) {
      if (hasSmoothScroll) viewScroll.scrollTo(anchorEl, { offset: 0, duration: 1.8 })
      else gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
    }
  }

  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': targetLocation.href
    })
  }
}

export const onLeave = (from, trigger) => {
  // Remove Previous Page Effects
  const { hasInView, hasSticky, hasPrllx, hasModals } = viewStorage
  if (hasInView) viewStorage.viewInView.destroy()
  if (hasSticky) viewStorage.viewSticky.destroy()
  if (hasPrllx) viewStorage.viewPrllx.destroy()
  if (hasModals) viewStorage.viewModals.destroy()

  if (globalStorage.Cursor) globalStorage.Cursor.removeHovers()

  // LoadingClasses
  const { body } = domStorage
  body.classList.add('--loading')
  body.classList.add('--animating')

  // Scroll
  const { hasSmoothScroll } = viewStorage
  body.style.overflow = 'hidden'
  if (hasSmoothScroll) viewStorage.viewScroll.destroy()

  const { menu } = domStorage
  const { menuOpen } = globalStorage

  // Close Menu
  if (menuOpen) globalStorage.closeMobileMenu()

  // Remove active links
  const { targetLocation } = globalStorage.taxi
  const navLinks = menu.querySelectorAll('.Nav__link')
  navLinks.forEach((link) => {
    if (link.href === targetLocation.raw) link.classList.add('--active')
    else link.classList.remove('--active')
  })

  // DynamicBanner
  if (dynamicBanners) dynamicBanners.destroy()
}
