import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import MobileSwiper from '../modules/mobile-swiper'
import WknSwiper from '../modules/wkn-swiper'
import Numbers from '../modules/numbers'

export default class PagePrestation extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      relatedPrestationsSwiper: viewStorage.current.querySelector('.RelatedPrestations .swiper'),
    }


    this.init()
  }

  init() {
    const { relatedPrestationsSwiper } = this.DOM 

    if (relatedPrestationsSwiper) this.relatedPrestationsSwiperModule = new MobileSwiper(relatedPrestationsSwiper)
  }

  onLeaveCompleted() {
    const { relatedPrestationsSwiperModule } = this
    
    if (relatedPrestationsSwiperModule) relatedPrestationsSwiperModule.destroy()
  }
}
