import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import AboutPrestations from '../animations/about-prestations'
import Numbers from '../modules/numbers'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'

export default class PageAbout extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      prestationsSection: viewStorage.current.querySelector('.AboutPrestations'),
      prestationsSwiper: viewStorage.current.querySelector('.AboutPrestations .swiper'),
      numbersSection: viewStorage.current.querySelector('.Numbers'),
      engagementsSwiper: viewStorage.current.querySelector('.Engagements .swiper')
    }

    this.init()
  }

  init() {
    const { prestationsSection, prestationsSwiper, numbersSection, engagementsSwiper } = this.DOM 

    if (prestationsSection) this.prestationsAboutModule = new AboutPrestations(prestationsSection)
    if (prestationsSwiper) this.prestationsSwiperModule = new MobileSwiper(prestationsSwiper)
    if (numbersSection) this.numbersModule = new Numbers(numbersSection)
    if (engagementsSwiper) this.engagementsSwiperModule = new WknSwiper(engagementsSwiper, { slidesPerView: 'auto' })
  }

  onLeaveCompleted() {
    const { prestationsAboutModule, prestationsSwiperModule, numbersModule, engagementsSwiperModule } = this
    
    if (prestationsAboutModule) prestationsAboutModule.destroy()
    if (prestationsSwiperModule) prestationsSwiperModule.destroy()
    if (numbersModule) numbersModule.destroy()
    if (engagementsSwiperModule) engagementsSwiperModule.destroy()
  }
}
