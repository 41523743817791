import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)

export default class AccompanimentSteps {
  constructor(container) {
    this.DOM = { container }
    this.DOM.stepsC = this.DOM.container.querySelector('.steps')
    this.DOM.steps = this.DOM.container.querySelectorAll('.step')

    if (!this.DOM.steps.length) return

    this.DOM.counterNumbers = this.DOM.container.querySelectorAll('.counter .current span')
    this.DOM.greenCircle = this.DOM.container.querySelector('.counter .circle svg circle.--green')

    this.setCircle()
    this.setIncrementation()
  }

  setCircle() {
    const { container, greenCircle } = this.DOM

    gsap.set(greenCircle, { drawSVG: '0%' })

    this.circleTween = gsap
      .timeline({
        defaults: { ease: 'none' },
        scrollTrigger: {
          trigger: container,
          start: 'top top',
          end: 'bottom bottom',
          scrub: true
        }
      })
      .to(greenCircle, { drawSVG: '100%' }, 0)
  }

  setIncrementation() {
    const { counterNumbers, steps } = this.DOM
    this.stepsTweens = []

    steps.forEach((step, index) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: step,
          start: 'top 40%',
          end: 'bottom 40%',
          toggleActions: 'play pause resume reset'
        },
      })
      .to(counterNumbers, { opacity: (i, target, targets) => index === i ? 1 : 0, duration: 0.01, ease: 'none' }, 0)
    })

  }

  destroy() {
    const { circleTween } = this

    if (circleTween) circleTween.kill()
  }
}