import { isDEVMODE, domStorage } from '../_globals'
import Stats from 'stats.js'

export default class Statistics {
  constructor() {
    if (!isDEVMODE) return

    this.setStats()
    this.animate()
  }

  setStats() {
    this.fps = new Stats()
    this.fps.showPanel(0)
    this.fps.domElement.style.cssText = "position:fixed;z-index:99999;bottom:0;right:0;";

    domStorage.body.appendChild(this.fps.dom)
  }

  animate() {
    this.fps.begin()
    this.fps.end()

    requestAnimationFrame(this.animate.bind(this))
  }
}