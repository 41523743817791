import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Sharing from '../modules/sharing'

export default class SingleJobOffer extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      sharing: viewStorage.current.querySelector('.Sharing')
    }

    this.init()
  }

  init() {
    const { sharing } = this.DOM

    if (sharing) this.sharingModule = new Sharing(sharing)
  }

  onLeaveCompleted() {
    const { sharingModule } = this

    if (sharingModule) sharingModule.destroy()
  }
}
