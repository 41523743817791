import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Form from '../modules/form'

export default class PageJoinUs extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      form: viewStorage.current.querySelector('.Form')
    }


    this.init()

  }

  init() {
    const { form } = this.DOM

    if (form) this.formModule = new Form(form)

  }

  onLeaveCompleted() {
    const { formModule } = this

    if (formModule) formModule.destroy()
  }
}
