import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Sharing from '../modules/sharing'
import WknSwiper from '../modules/wkn-swiper'
import WysiwygWithVideos from '../modules/wysiwyg-with-videos'

export default class SingleRealisation extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      sharing: viewStorage.current.querySelector('.Sharing'),
      headerSwiper: viewStorage.current.querySelector('.PageHeaderRealisation .swiper'),
      description: viewStorage.current.querySelector('.RealisationDescription')
    }

    this.init()
  }

  init() {
    const { sharing, headerSwiper, description } = this.DOM

    if (sharing) this.sharingModule = new Sharing(sharing)
    if (headerSwiper) this.headerSwiperModule = new WknSwiper(headerSwiper, { slidesPerView: 'auto' })
    if (description) this.wysiwygModule = new WysiwygWithVideos(description)
  }

  onLeaveCompleted() {
    const { sharingModule, headerSwiperModule, wysiwygModule } = this

    if (sharingModule) sharingModule.destroy()
    if (headerSwiperModule) headerSwiperModule.destroy()
    if (wysiwygModule) wysiwygModule.destroy()
  }
}
