import { isDEVMODE, domStorage, viewStorage } from '../_globals'

export default class QuotationForm {
  constructor(formSelector) {
    isDEVMODE && console.log('Init QuotationForm')
    this.DOM = { form: formSelector }

    this.DOM.inputs = this.DOM.form.querySelectorAll('input, textarea')
    this.DOM.fields = this.DOM.form.querySelectorAll('input, select, textarea')
    this.DOM.resultText = this.DOM.form.querySelector('.Form__output p')
    this.DOM.submitBtn = this.DOM.form.querySelector('button[type=submit]')

    this.DOM.allSelectsWithFakeLabel = this.DOM.form.querySelectorAll('.select-container.--has-fake-label select')

    this.DOM.needSelect = this.DOM.form.querySelector('select#quotation_need')
    this.DOM.providerCheckboxes = this.DOM.form.querySelectorAll('.preferences-block.--provider .Checkbox input')
    this.DOM.typeCheckboxes = this.DOM.form.querySelectorAll('.preferences-block.--type .Checkbox input')

    this.formURL = this.DOM.form.dataset.ajaxurl

    this.addEvents()
  }

  addEvents() {
    const { form, inputs, needSelect, allSelectsWithFakeLabel = null } = this.DOM

    this.onSubmit = this.onSubmit.bind(this)
    this.showAllForm = this.showAllForm.bind(this)
    this.showHiddenFormGroups = this.showHiddenFormGroups.bind(this)

    form.addEventListener('submit', this.onSubmit)

    this.onFocus = this.onFocus.bind(this)
    inputs.forEach(input => input.addEventListener('input', this.onFocus))
    inputs.forEach(input => input.addEventListener('focusin', this.onFocus))
    inputs.forEach(input => input.addEventListener('focusout', this.onFocus))

    if (allSelectsWithFakeLabel && allSelectsWithFakeLabel.length) {
      allSelectsWithFakeLabel.forEach(select => {
        select.addEventListener('change', (e) => !e.currentTarget.parentNode.classList.contains('--has-value') && e.currentTarget.parentNode.classList.add('--has-value'))
      })
    }

    needSelect.addEventListener('change', this.showAllForm)
    needSelect.addEventListener('change', this.showHiddenFormGroups)


  }

  showAllForm(e) {
    e.preventDefault()

    const { form } = this.DOM
    const { value } = e.target

    const className = '--show-all-form'

    if (value) form.classList.add(className)
  }

  showHiddenFormGroups(e) {
    e.preventDefault()

    const { form, providerCheckboxes, typeCheckboxes } = this.DOM
    const { value } = e.target
    const className = '--show-hidden-form-groups'

    if (value === 'Entretien espace extérieur') {
      form.classList.add(className)

      // providerCheckboxes.forEach(input => input.required = true)
      // typeCheckboxes.forEach(input => input.required = true)
    } else {
      form.classList.remove(className)

      // providerCheckboxes.forEach(input => input.required = false)
      // typeCheckboxes.forEach(input => input.required = false)
    }
  }

  onFocus(e) {
    const { type, currentTarget } = e
    if (type === 'focusin' || type === 'input') currentTarget.parentNode.classList.add('--focused')
    else currentTarget.parentNode.classList.remove('--focused')

    if (type === 'focusout' && currentTarget.value !== '') currentTarget.parentNode.classList.add('--filled')
    else currentTarget.parentNode.classList.remove('--filled')
  }

  onSubmit(e) {
    e.preventDefault()

    const { body } = domStorage
    // const { viewScroll } = viewStorage
    const { fields, submitBtn } = this.DOM

    body.classList.add('--loading')

    submitBtn.disabled = true

    /* Remove previous errors */
    this.resetErrors()

    /* Add values to formData */
    const formData = this.getData()

    const xhr = new XMLHttpRequest()
    xhr.open('POST', this.formURL, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {

        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText)
            break
          case 422:
            this.onInvalid(xhr.responseText)
            break
          default:
            this.onServerError()
        }

        submitBtn.disabled = false

        fields.forEach(field => {
          field.disabled = false
        })

        body.classList.remove('--loading')
        // if (viewScroll) viewScroll.update()
      }
    }

    xhr.send(formData)
  }

  resetErrors() {
    const { form, resultText } = this.DOM

    resultText.innerHTML = ''
    resultText.classList.remove('--error')

    const errors = form.querySelectorAll('span.--error')
    errors.forEach((err) => {
      err.parentNode.classList.remove('--error')
      err.parentNode.removeChild(err)
    })

  }

  getData() {
    const { fields } = this.DOM

    const formData = new FormData()
    fields.forEach(field => {
      field.disabled = true
      if (field.type === 'radio' && !field.checked) return
      if (field.type === 'checkbox') {
        if (field.checked) formData.append(field.name, field.value)
      } else if (field.type === 'file') {
        formData.append(field.name, field.files[0])
      } else {
        formData.append(field.name, field.value)
      }
    })

    return formData
  }

  onSuccess(text) {
    const { form, resultText } = this.DOM

    resultText.innerHTML = text
    form.reset()
  }

  onInvalid(text) {
    const { form } = this.DOM
    const json = JSON.parse(text)

    for (const fieldName of Object.keys(json)) {
      const fields = form.querySelectorAll(`[name=${fieldName}]`)
      if (fields.length > 0) {
        let field
        if (fields.length === 1) {
          field = fields[0]
        }
        else {
          field = fields[fields.length - 1]
        }
        const err = document.createElement('span')
        err.innerHTML = json[fieldName].join('. ')
        err.classList.add('--error')
        field.parentNode.appendChild(err)
        field.parentNode.classList.add('--error')
      }
      const name = fieldName + '[]'
      // console.log('name', name)
      const checkboxes = form.querySelectorAll(`[name="${name}"]`)
      const lastFieldMultipleCheckboxCase = form.querySelector(`[name="${name}"]`)
      if (lastFieldMultipleCheckboxCase) {
        const err = document.createElement('span')
        err.innerHTML = json[fieldName].join('. ')
        err.classList.add('--error')
        lastFieldMultipleCheckboxCase.parentNode.parentNode.appendChild(err)
        checkboxes.forEach(checkbox => checkbox.parentNode.classList.add('--error'))
      }
    }
  }

  onServerError() {
    const { resultText } = this.DOM

    resultText.innerHTML = 'Une erreur est survenue. Merci de réessayer ulterieurement'
    resultText.classList.add('--error')
  }

  destroy() {
    const { form, inputs, needSelect } = this.DOM

    form.removeEventListener('submit', this.onSubmit)
    inputs.forEach(input => input.removeEventListener('focusin', this.onFocus))
    inputs.forEach(input => input.removeEventListener('focusout', this.onFocus))
    needSelect.removeEventListener('change', this.showHiddenFormGroups.bind(this))
  }
}
