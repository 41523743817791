import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import Numbers from '../modules/numbers'
import Values from '../modules/values'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class PageJoinUs extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      headerAnchor: viewStorage.current.querySelector('.PageHeader .--anchor'),
      numbersSection: viewStorage.current.querySelector('.Numbers'),
      valuesSection: viewStorage.current.querySelector('.Values'),
      partnersSwiper: viewStorage.current.querySelector('.Partners .swiper')
    }

    this.init()
  }

  init() {
    const { headerAnchor, numbersSection, valuesSection, partnersSwiper } = this.DOM 

    this.setAnchor = this.setAnchor.bind(this)

    if (headerAnchor) headerAnchor.addEventListener('click', this.setAnchor)
    if (numbersSection) this.numbersModule = new Numbers(numbersSection)
    if (valuesSection) this.valuesModule = new Values(valuesSection)
    if (partnersSwiper) this.partnersSwiperModule = new WknSwiper(partnersSwiper, { slidesPerView: 'auto' })
  }

  setAnchor(e) {
    e.preventDefault()

    const { headerAnchor } = this.DOM

    gsap.to(window, { scrollTo: headerAnchor.dataset.anchor, duration: 2, ease: 'power3.inOut' })
  }

  onLeaveCompleted() {
    const { headerAnchor, numbersModule, valuesModule, partnersSwiperModule } = this
    
    if (headerAnchor) headerAnchor.removeEventListener('click', this.setAnchor)
    if (numbersModule) numbersModule.destroy()
    if (valuesModule) valuesModule.destroy()
    if (partnersSwiperModule) partnersSwiperModule.destroy()
  }
}
