import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from './page-intro'

export const GlobalIntro = () => {
  const { intro, body } = domStorage
  const { hasSmoothScroll, viewScroll } = viewStorage

  gsap
    .timeline({
      paused: true,
      delay: 0.2,
      defaults: { duration: 1, ease: 'power3.inOut' },
      onStart: () => {
        body.classList.remove('--preloading')
        body.classList.remove('--loading')

        // Stop Scroll
        // if (hasSmoothScroll) viewScroll.scroll.stop()
      },
      onComplete: () => {
        ScrollTrigger.refresh()
        body.classList.remove('--animating')
        body.classList.remove('--intro')

        // Start Scroll
        // if (hasSmoothScroll) viewScroll.scroll.start()

        globalStorage.firstLoad = false
      }
    })
    .set(domStorage.header, { opacity: 0 }, 0)
    .add(pageIntro(), 0)
    .play()
}
