import { viewStorage } from '../_globals'
import WknPlyr from './wkn-plyr'

export default class DynamicBanners { 
  constructor() {
    this.DOM = {
      plyrs: viewStorage.current.querySelectorAll('.DynamicBanner .plyr__container')
    }

    this.plyrs = []

    if (!this.DOM.plyrs.length) return

    this.DOM.plyrs.forEach(item => {
      const plyr = new WknPlyr(item)
      this.plyrs.push(plyr)
    })
  }

  destroy() {
    const { plyrs } = this

    if (!plyrs.length) return

    plyrs.forEach(item => item.destroy())
  }
}
