import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import QuotationForm from '../modules/quotation-form'
import gsap from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(DrawSVGPlugin)

export default class PageQuotationRequest extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      form: viewStorage.current.querySelector('.Form'),
      steps: viewStorage.current.querySelectorAll('.QuotationStep')
    }


    this.init()
  }

  init() {
    const { form, steps } = this.DOM

    if (form) this.formModule = new QuotationForm(form)
    if (steps.length) this.formatStepsCircles()
  }

  formatStepsCircles() {
    const { steps } = this.DOM

    steps.forEach((step, index) => {
      const circle = step.querySelector('.Icon[data-name="step"] circle:nth-child(2)')
      const percentage = 25 * (index + 1)

      gsap.fromTo(circle, {
        drawSVG: '0%'
      }, { 
        scrollTrigger: {
          trigger: step
        },
        drawSVG: `${percentage}%`,
      })
    })
  }

  onLeaveCompleted() {
    const { formModule } = this

    if (formModule) formModule.destroy()
  }
}
