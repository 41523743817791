import { domStorage, viewStorage } from '../_globals';
import Lenis from '@studio-freight/lenis'

export default class viewScroll {
  constructor() {
    this.DOM = {
      container: domStorage.body.querySelector('[data-scroll-container]')
    }

    this.init()
  }
  
  init() {
    viewStorage.hasSmoothScroll = true

    this.scroll = new Lenis({
      duration: 0.82,
      easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
      smooth: true,
      direction: 'vertical'
    })

    const raf = (time) => {
      this.scroll.raf(time)
      this.raf = requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
  }

  destroy() {
    window.cancelAnimationFrame(this.raf);
    this.id = undefined;
    this.scroll.destroy()
    viewStorage.viewScroll = null
  }
}
