import { isDEVMODE, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Values {
  constructor(container) {
    this.DOM = { container }
    this.DOM.cards = this.DOM.container.querySelectorAll('.ValuesCard')

    if (!this.DOM.cards.length) return

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setParallaxCards()
  }

  setParallaxCards() {
    const { container, cards } = this.DOM

    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        this.tl = gsap
        .timeline({
          defaults: { ease: 'none' },
          scrollTrigger: {
            trigger: container,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true
          }
        })
        .fromTo(cards, {
          yPercent: index => index < 1 ? 55 : -15,
          rotate: index => index < 1 ? -2 : 2
        }, {
          yPercent: index => index < 1 ? -35 : 15,
          rotate: index => index < 1 ? 4 : -2,
        }, 0)
      }
    })
  }

  destroy() {
    const { tl } = this

    if (tl) tl.kill()
  }
}
